import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const SpinnerOverlay = () => {
  return (
    <Overlay>
      <Spinner />
    </Overlay>
  );
};

export default SpinnerOverlay;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
`;

const Spinner = styled(CircularProgress)`
  position: fixed;
  top: calc(50vh - 20px);
  left: calc(50vw - 20px);
`;
